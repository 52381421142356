import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  textInput: {
    margin: '20px 0',
    padding: '10px',
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/oldPaper.jpg)`,
    border: 'none',
    outline: 'none',
    fontSize: '1.5em',
    width: '100%',
    maxWidth: '100%',
    opacity: 1,
    textAlign: 'center',
    color: '#000',
    // prevent iOS making text grey in disabled state
    '-webkit-text-fill-color': '#000',
    '&::placeholder': {
      color: '#222'
    }
  },
  textArea: {
    resize: 'none',
    height: '250px',
    fontSize: '1em',
    backgroundAttachment: 'local',
    textAlign: 'left'
  },
  noWrap: {
    textWrap: 'nowrap'
  }
}));
