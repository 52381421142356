import React, {
  useCallback,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { IStretchedText } from 'src/interfaces/IPuzzles';
import { checkAnswer } from 'src/utils/string';
import { useResize } from 'src/hooks/Resize';
import TextInput from '../TextInput';
import { useStyles } from './styles';

function StretchedText({
  data,
  onComplete,
  isComplete
}: IStretchedText): React.ReactElement {
  const styles = useStyles();
  const [currentAnswer, setCurrentAnswer] = useState('');
  const container = useRef<HTMLDivElement>(null);
  const secret = useRef<HTMLDivElement>(null);
  const decoy = useRef<HTMLDivElement>(null);

  const onInputChange = useCallback((theirAnswer: string) => {
    setCurrentAnswer(theirAnswer);
    if (!isComplete && checkAnswer(theirAnswer, data.answer)) {
      onComplete();
    }
  }, [data.answer, isComplete, onComplete]);

  const setSize = useCallback(() => {
    const containerHeight = container.current?.offsetHeight || 1;
    const containerWidth = container.current?.offsetWidth || 1;

    const decoyHeight = decoy.current?.offsetHeight || 1;
    const decoyWidth = decoy.current?.offsetWidth || 1;
    const decoyScaleYAmount = containerHeight / decoyHeight;
    const decoyScaleXAmount = containerWidth / decoyWidth;
    decoy.current?.style.setProperty('transform', `scaleY(${decoyScaleYAmount * 1.6}) scaleX(${decoyScaleXAmount * 1})`);

    const secretHeight = secret.current?.offsetHeight || 1;
    const secretWidth = secret.current?.offsetWidth || 1;
    const secretScaleYAmount = containerWidth / secretHeight;
    const secretScaleXAmount = containerHeight / secretWidth;
    secret.current?.style.setProperty('transform', `scaleY(${secretScaleYAmount * 1.6}) scaleX(${secretScaleXAmount})`);
  }, []);

  useLayoutEffect(setSize, [setSize]);
  useResize(setSize);

  return (
    <div className={styles.container}>
      <div className={styles.fullSize}>
        <div className={styles.words} ref={container}>
          <div className={styles.stretch} ref={decoy}>{data.decoy}</div>
          <div className={styles.rotated}>
            <div className={styles.stretch} ref={secret}>{data.secretMessage}</div>
          </div>
        </div>
      </div>
      <TextInput
        onChange={onInputChange}
        displayOnly={isComplete}
        value={isComplete ? data.answer : currentAnswer}
      />
    </div>
  );
}

export default StretchedText;
