import React from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';

interface IProps {
  onChange?: (val: string) => void;
  value?: string;
  textArea?: boolean;
  displayOnly?: boolean;
  placeholder?: string;
  noWrap?: boolean;
  className?: string;
}

function TextInput({
  onChange: handleChange,
  textArea,
  value,
  displayOnly,
  placeholder,
  noWrap,
  className
}: IProps): React.ReactElement {
  const styles = useStyles();
  const onChange = handleChange || (() => {});
  const currValue = value ? value.toLocaleUpperCase() : '';

  if (textArea) {
    return (
      <textarea
        className={clsx(styles.textInput, styles.textArea, { [styles.noWrap]: noWrap }, className)}
        onChange={(ev) => onChange(ev.target.value.toLocaleUpperCase())}
        value={currValue}
        disabled={displayOnly}
        placeholder={placeholder}
      />
    );
  }

  return (
    <input
      className={clsx(styles.textInput, className)}
      onChange={(ev) => onChange(ev.target.value.toLocaleUpperCase())}
      disabled={displayOnly}
      placeholder={placeholder}
      value={currValue}
    />
  );
}

TextInput.defaultProps = {
  onChange: () => {},
  value: '',
  textArea: false,
  displayOnly: false,
  placeholder: '',
  noWrap: false,
  className: ''
};

export default TextInput;
