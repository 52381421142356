import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  container: {

  },
  lwlInstructions: {
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: theme.typography.body1.fontSize
  },
  lwlExampleText: {
    textAlign: 'center',
    height: '80px',
    margin: '0px'
  },
  clue: {
    textAlign: 'center',
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: theme.typography.body1.fontSize
  },
  lwlCode: {
    textAlign: 'center',
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    margin: '20px 0px'
  }
}));
