import React from 'react';
import { IAudio } from 'src/interactions/audio';

type UtilsContextType = {
  showToast: (message: string, isError?: boolean) => () => void;
  playSound: (sound: IAudio) => void;
};

export const defaultUtilsContext: UtilsContextType = {
  showToast: () => () => {},
  playSound: () => {}
};

export const UtilsContext = React.createContext(defaultUtilsContext);
