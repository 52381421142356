import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  hints: {
    textAlign: 'center'
  },
  hintBtn: {
    margin: '10px auto',
    transition: '0.5s ease-in-out',
    pointerEvents: 'none',
    opacity: 0
  },
  hint: {
    margin: '10px 0'
  },
  showHintBtn: {
    pointerEvents: 'auto',
    opacity: 1,
    animation: '$shake 0.7s',
    animationIterationCount: 1
  },
  '@keyframes shake': {
    '0%': { transform: 'translate(1px, 1px) rotate(0deg)' },
    '10%': { transform: 'translate(-1px, -2px) rotate(-1deg)' },
    '20%': { transform: 'translate(-3px, 0px) rotate(1deg)' },
    '30%': { transform: 'translate(3px, 2px) rotate(0deg)' },
    '40%': { transform: 'translate(1px, -1px) rotate(1deg)' },
    '50%': { transform: 'translate(-1px, 2px) rotate(-1deg)' },
    '60%': { transform: 'translate(-3px, 1px) rotate(0deg)' },
    '70%': { transform: 'translate(3px, 1px) rotate(-1deg)' },
    '80%': { transform: 'translate(-1px, -1px) rotate(1deg)' },
    '90%': { transform: 'translate(1px, 2px) rotate(0deg)' },
    '100%': { transform: 'translate(1px, -2px) rotate(-1deg)' }
  }
}));
