import React from 'react';
import AdminPortal from './components/AdminPortal';
import SignIn from './components/SignIn';
import UploadLocation from './components/UploadLocation';
import Quest from './puzzles/Quest';
import ViewLocations from './components/ViewLocations';
import ListQuests from './components/ListQuests';

type IRoute = {
  path: string;
  component: () => React.ReactElement;
  privateRoute: boolean;
  adminRoute?: boolean;
  param?: string;
};

type IRouteName = 'ADMIN_PORTAL'|'UPLOAD_LOCATION'|'SIGN_IN'|'QUESTS'|'QUEST'|'VIEW_LOCATIONS';

export const ROUTE: Record<IRouteName, IRoute> = {
  ADMIN_PORTAL: {
    path: '/admin-portal',
    privateRoute: true,
    adminRoute: true,
    component: () => <AdminPortal />
  },
  UPLOAD_LOCATION: {
    path: '/upload-location',
    privateRoute: true,
    adminRoute: true,
    component: () => <UploadLocation />
  },
  VIEW_LOCATIONS: {
    path: '/locations',
    privateRoute: true,
    adminRoute: true,
    component: () => <ViewLocations />
  },
  SIGN_IN: {
    path: '/sign-in',
    privateRoute: false,
    component: () => <SignIn />
  },
  QUESTS: {
    path: '/quests',
    privateRoute: true,
    component: () => <ListQuests />
  },
  QUEST: {
    path: '/quest',
    param: 'id',
    privateRoute: true,
    component: () => <Quest />
  }
};

export const DEFAULT_ROUTE_PATH = ROUTE.QUESTS.path;
