import React, { useContext } from 'react';
import { UserContext } from 'src/providers/user/UserProvider';
import { useHistory } from 'react-router-dom';
import { ROUTE } from 'src/routes';
import { QuestContext } from 'src/providers/quest/QuestContext';
import Button from '../Button';
import { useStyles } from './styles';

function AdminPortal(): React.ReactElement {
  const styles = useStyles();
  const { user } = useContext(UserContext);
  const { addNewQuest } = useContext(QuestContext);
  const history = useHistory();

  return (
    <div className={styles.container}>
      <img src={user?.photoURL} alt="user" />
      <p>{`Welcome ${user?.displayName}!`}</p>

      {
        Object.keys(ROUTE).map((name) => (
          <Button
            key={name}
            className={styles.button}
            onClick={() => history.push(ROUTE[name].path)}
            text={ROUTE[name].path.slice(1)}
          />
        ))
      }
      <Button
        className={styles.button}
        onClick={() => addNewQuest()}
        text="add new quest"
      />
    </div>
  );
}

export default AdminPortal;
