import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  caeser: {
    margin: '20px 0px'
  }
}));
