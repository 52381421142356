
import React, { useState, ReactNode, useEffect } from 'react';
import {
  auth,
  facebookProvider,
  getOrSetUserFB,
  googleProvider
} from 'src/firebase';
import { ADMIN_USERS } from 'src/admin';
import { IUser } from 'src/interfaces/IUser';
import { UserContext } from './UserContext';

interface IProps {
  children?: ReactNode;
}

function UserProvider({ children }: IProps): React.ReactElement {
  const [user, setUser] = useState<IUser|null>();
  const [isSigningIn, setIsSigningIn] = useState(false);

  async function signInWithFacebook(): Promise<void> {
    // TODO I need to set this up before it will work
    // "Your app has standard access to public_profile.
    // To use Facebook Login, switch public_profile to advanced access."
    setIsSigningIn(true);
    await auth.signInWithRedirect(facebookProvider);
    setIsSigningIn(false);
  }

  async function signInWithGoogle(): Promise<void> {
    setIsSigningIn(true);
    await auth.signInWithRedirect(googleProvider);
    setIsSigningIn(false);
  }

  function signOut(): Promise<void> {
    return auth.signOut();
  }

  function isAdmin(): boolean {
    return !!user?.uid && ADMIN_USERS.includes(user.uid);
  }

  useEffect(() => {
    auth.onAuthStateChanged(async (userAuth) => {
      const newUser = await getOrSetUserFB(userAuth);
      setUser(newUser);
    });
  }, []);

  return (
    <UserContext.Provider
      value={
        {
          user,
          signInWithFacebook,
          signInWithGoogle,
          signOut,
          isSigningIn,
          get isAdmin() {
            return isAdmin();
          }
        }
      }
    >
      {children}
    </UserContext.Provider>
  );
}

UserProvider.defaultProps = {
  children: null
};

export { UserContext, UserProvider };
