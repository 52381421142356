import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { IS_DEV } from 'src';
import { useStyles } from './styles';
import Button from '../Button';
import Accordian from '../Accordian';

interface IProps {
  hints: string[];
}

function Hints({ hints }: IProps): React.ReactElement {
  const styles = useStyles();
  const [showHintNumber, setShowHintNumber] = useState(0);
  const [showHintBtn, setShowHintBtn] = useState(false);
  const timeout = useRef<number>();

  function showHintBtnAfterTimeout(): void {
    timeout.current = window.setTimeout(() => setShowHintBtn(true), IS_DEV ? 500 : 30000);
  }

  function showHint(): void {
    const newHintNumber = showHintNumber + 1;
    if (newHintNumber <= hints.length) {
      setShowHintNumber(newHintNumber);
      if (newHintNumber < hints.length) {
        setShowHintBtn(false);
        showHintBtnAfterTimeout();
      }
    }
  }

  function renderShowHintBtn(): React.ReactElement {
    return (
      <Button
        onClick={() => showHint()}
        text={`Hint ${showHintNumber}/${hints.length}`}
        className={clsx(styles.hintBtn, { [styles.showHintBtn]: showHintBtn })}
        disabled={showHintNumber === hints.length}
      />
    );
  }

  useEffect(() => {
    showHintBtnAfterTimeout();
    return () => clearTimeout(timeout.current);
  }, []);

  return (
    <div className={styles.hints}>
      {renderShowHintBtn()}
      {
        hints.map((hint, index) => (
          <Accordian
            key={hint}
            isOpen={index < showHintNumber}
          >
            <div className={styles.hint}>{hint}</div>
          </Accordian>
        ))
      }
    </div>
  );
}

export default Hints;
