import React, {
  useContext
} from 'react';
import {
  GoogleMap,
  Marker
} from '@react-google-maps/api';
import { LocationContext } from 'src/providers/location/LocationContext';
import { IPosition } from 'src/interfaces/ILocation';
import { UtilsContext } from 'src/providers/utils/UtilsContext';
import mapTheme from './mapTheme.json';
import { useStyles } from './styles';

const mapOptions: google.maps.MapOptions = {
  styles: mapTheme,
  disableDefaultUI: true
};

const LOCATION_WARNING = 'If you want to see where you are on the map then you need to grant location permissions';

function Map({
  lat,
  lng
}: IPosition): React.ReactElement {
  const styles = useStyles();
  const { myLocation, myMarker, xMarker } = useContext(LocationContext);
  const xMark = { lat, lng };
  const { showToast } = useContext(UtilsContext);

  return (
    <div className={styles.container}>
      <GoogleMap
        options={mapOptions}
        mapContainerClassName={styles.mapContainer}
        center={xMark}
        zoom={13}
      >
        <Marker
          position={xMark}
          icon={xMarker}
        />
        {
          myLocation && (
            <Marker
              position={myLocation}
              icon={myMarker}
            />
          )
        }
      </GoogleMap>
      {
        !myLocation && (
          <button
            onClick={() => showToast(LOCATION_WARNING, true)}
            type="button"
            className={styles.locationWarning}
          >
            <img
              className={styles.locationWarningIcon}
              src={`${process.env.PUBLIC_URL}/icons/warning.svg`}
              alt={LOCATION_WARNING}
            />
          </button>
        )
      }
    </div>
  );
}

export default React.memo(Map);
