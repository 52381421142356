import React, { useCallback, useContext } from 'react';
import {
  GoogleMap,
  Marker
} from '@react-google-maps/api';
import { IPosition } from 'src/interfaces/ILocation';
import { LocationContext } from 'src/providers/location/LocationContext';
import { useStyles } from './styles';

const BYRON = { lat: -28.648, lng: 153.612 };

interface IProps {
  setSpot: (spot: IPosition) => void;
  spot?: IPosition;
}

function AdminMap({ setSpot, spot }: IProps): React.ReactElement {
  const styles = useStyles();
  const { myLocation, myMarker, xMarker } = useContext(LocationContext);

  const addNewMarker = useCallback((e: google.maps.MapMouseEvent): void => {
    const center = e.latLng?.toJSON();
    if (!center) return;
    const newSpot: IPosition = {
      lat: center.lat,
      lng: center.lng
    };

    setSpot(newSpot);
  }, [setSpot]);

  const getDistance = useCallback(() => {
    if (!myLocation || !spot) return '';
    const distance = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(myLocation),
      new google.maps.LatLng(spot)
    );
    return `${Math.round(distance)}m`;
  }, [myLocation, spot]);

  return (
    <GoogleMap
      mapContainerClassName={styles.mapContainer}
      center={BYRON}
      zoom={13}
      onClick={addNewMarker}
    >
      {myLocation && <Marker position={myLocation} icon={myMarker} label={getDistance()} />}
      {spot && <Marker position={spot} icon={xMarker} />}
    </GoogleMap>
  );
}

AdminMap.defaultProps = {
  spot: undefined
};

export default React.memo(AdminMap);
