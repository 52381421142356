/* eslint-disable @typescript-eslint/indent */
import { makeWordSearch } from 'src/components/Wordsearch/utils';
import {
  ICaeserCipher,
  IClueInput,
  ILocationInput,
  IQuest,
  ISafe,
  IStretchedText,
  IWordSearch,
  PUZZLE_TYPE
} from 'src/interfaces/IPuzzles';
import {
  generateId,
  getLWL,
  getQuestName,
  getRandomSafeNumber
} from 'src/utils/string';

function createWordsearch(secretMessage: string): IWordSearch {
  return {
    type: PUZZLE_TYPE.WORDSEARCH,
    id: generateId(),
    isComplete: false,
    onComplete: () => {},
    data: makeWordSearch(secretMessage)
  };
}

function createSafe(code: string): ISafe {
  return {
    type: PUZZLE_TYPE.SAFE,
    id: generateId(),
    isComplete: false,
    onComplete: () => {},
    data: code
  };
}

function createLocationInput(lat: number, lng: number, answer: string, clue: string): ILocationInput {
  return {
    type: PUZZLE_TYPE.LOCATION_INPUT,
    id: generateId(),
    isComplete: false,
    onComplete: () => {},
    data: {
      lat,
      lng,
      answer,
      clue
    }
  };
}

function createLWL(
  lat: number,
  lng: number,
  answer: string,
  passageDescription: string,
  passageText: string
): ILocationInput {
  return {
    type: PUZZLE_TYPE.LOCATION_INPUT,
    id: generateId(),
    isComplete: false,
    onComplete: () => {},
    data: {
      lat,
      lng,
      answer,
      clue: `Now go and look for the passage "${passageDescription}"`,
      lwl: {
        passageDescription,
        passageText,
        lwlCode: `${getLWL(passageText, answer)} = ?`
      }
    }
  };
}

function createCaeserCipher(secretMessage: string, answer: string): ICaeserCipher {
  return {
    type: PUZZLE_TYPE.CAESER_CIPHER,
    id: generateId(),
    isComplete: false,
    onComplete: () => {},
    data: {
      secretMessage,
      answer
    }
  };
}

function createStretchedText(secretMessage: string, decoy: string, answer: string): IStretchedText {
  return {
    type: PUZZLE_TYPE.STRETCHED_TEXT,
    id: generateId(),
    isComplete: false,
    onComplete: () => {},
    data: {
      secretMessage,
      decoy,
      answer
    }
  };
}

function createClueInput(clue: string, answer: string, hints?: string[]): IClueInput {
  return {
    type: PUZZLE_TYPE.CLUE_INPUT,
    id: generateId(),
    isComplete: false,
    onComplete: () => {},
    data: {
      clue,
      answer,
      hints
    }
  };
}

export function createQuest(): IQuest {
  if (false) {
    return {
      id: generateId(),
      name: getQuestName(),
      steps: [
        createLWL(-28.643221, 153.620869, 'scallop', 'history of byron',
          `The quick brown
fox jumps over
the lazy dog`),
        createLocationInput(-28.643221, 153.620869, 'scallop', 'Find the name of the rusty dolphin'),
        createSafe(getRandomSafeNumber())
      ]
    };
  }
  return {
    id: generateId(),
    name: getQuestName(),
    steps: [
      createWordsearch('Look inside the skull'),
      createClueInput('', 'Towel'),
      createStretchedText('Look in the cats house', 'in the sink by washing machine', 'carrot'),
      createClueInput('Check the under the wooden spoons', '736', ['heated situations can bring out the truth', 'heat me up, but be careful!']),
      createClueInput('look on top of the fridge', ''),
      createCaeserCipher('', 'well done you win')
    ]
  };
}
