import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    position: 'fixed'
  },
  toasts: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pointerEvents: 'none',
    padding: '5px 20px'
  },
  toast: {
    padding: '10px 30px',
    margin: '10px 0px',
    borderRadius: '5px',
    background: 'rgba(0, 0, 0, 0.9)',
    color: '#ddd',
    textAlign: 'center'
  },
  error: {
    background: 'rgba(255, 0, 0, 0.9)'
  }
}));
