import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  container: {

  },
  mapContainer: {
    width: '100%',
    paddingBottom: '100%',
    borderRadius: '100%',
    margin: '20px auto',
    transform: 'translateZ(0)' // for some reason ios ignores the border radius without this
  },
  '@global': {
    /* hide map legend */
    '.gm-style-cc': {
      display: 'none'
    }
  },
  locationWarning: {
    margin: 'auto',
    marginBottom: '20px',
    display: 'block',
    background: 'none',
    border: 'none',
    outline: 'none'
  },
  locationWarningIcon: {
    height: '30px',
    width: '30px'
  }
}));
