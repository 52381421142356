import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  container: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  buttonContainer: {
    width: '200px'
  },
  logo: {
    width: '100%'
  },
  googleButton: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/btn_google_signin.png)`,
    backgroundSize: 'contain',
    width: '100%',
    // by using a container and setting the padding bottom on the button then we can
    // preserve the aspect ratio of the button image
    paddingBottom: '20%',
    backgroundColor: '#4285f4',
    borderRadius: '5px'
  }
}));
