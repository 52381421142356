import React from 'react';
import { ILocationDownload } from 'src/interfaces/ILocation';
import { deleteLocationFB } from 'src/firebase/geoquery';
import { getLWL } from 'src/utils/string';
import { useStyles } from './styles';
import Images from '../Images';
import Map from '../Map';
import Button from '../Button';
import TextInput from '../TextInput';

function ViewLocation(location: ILocationDownload): React.ReactElement {
  const styles = useStyles();
  const {
    id,
    clue,
    lineWordLetter,
    images
  } = location;

  return (
    <div className={styles.container}>
      <div className={styles.id}>{id}</div>
      <div className={styles.info}>{clue}</div>
      <Map {...location} />
      {
        lineWordLetter && (
          <>
            <TextInput
              textArea
              value={lineWordLetter}
              displayOnly
              noWrap
            />
            <div>{getLWL(lineWordLetter, 'hello')}</div>
          </>
        )
      }
      {images && <Images images={images} />}
      <Button
        onClick={() => deleteLocationFB(id)}
        text="Delete"
        className={styles.delete}
      />
    </div>
  );
}

export default ViewLocation;
