import { firestore } from 'src/firebase';
import { IQuest } from 'src/interfaces/IPuzzles';
import { createQuest } from 'src/puzzles/questUtils';
import { parse, stringify } from 'src/utils/string';

const QUESTS = 'quests/';

export async function createQuestsFB(uid: string): Promise<void> {
  try {
    const questsRef = firestore.doc(`${QUESTS}${uid}`);
    await questsRef.set({ quests: [stringify(createQuest())] });
  } catch (error) {
    console.error('Error creating user document', error);
  }
}

export async function getQuestsFB(uid: string): Promise<IQuest[]|null> {
  try {
    const questsDocument = await firestore.doc(`${QUESTS}${uid}`).get();
    const data = questsDocument.data();
    if (!data) return null;
    return data.quests.map((q) => parse<IQuest>(q));
  } catch (error) {
    console.error('Error fetching quests', error);
    return null;
  }
}

export async function updateQuestFB(uid: string, quest: IQuest): Promise<void> {
  if (!uid) return;
  try {
    const questsRef = firestore.doc(`${QUESTS}${uid}`);
    const doc = await questsRef.get();
    const currentQuests = doc.data()?.quests;
    if (!currentQuests) return;
    await questsRef.update({
      quests: currentQuests.map((q) => {
        const parsed = parse<IQuest>(q);
        if (parsed.id === quest.id) return stringify(quest);
        return q;
      })
    });
  } catch (error) {
    console.error('Error updating quests document', error);
  }
}

export async function addNewQuestFB(uid: string, quest: IQuest): Promise<void> {
  if (!uid) return;
  try {
    const questsRef = firestore.doc(`${QUESTS}${uid}`);
    const doc = await questsRef.get();
    const currentQuests = doc.data()?.quests || [];
    currentQuests.push(stringify(quest));
    await questsRef.update({ quests: currentQuests });
  } catch (error) {
    console.error('Error updating quests document', error);
  }
}

export async function subscribeToQuestsFB(uid: string, callback: (quests: IQuest[]) => void): Promise<() => void> {
  const unsubscribe = firestore.doc(`${QUESTS}${uid}`).onSnapshot((snapshot) => {
    const data = snapshot.data();
    if (!data) return;
    const { quests } = data;
    if (!quests) return;
    callback(quests.map((q) => parse<IQuest>(q)));
  });
  return unsubscribe;
}
