import React, { useContext } from 'react';
import {
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import PrivateRoute from 'src/containers/PrivateRoute';
import Loading from 'src/components/Loading';
import { ROUTE } from 'src/routes';
import { UserContext } from 'src/providers/user/UserContext';

function App(): React.ReactElement {
  const { user } = useContext(UserContext);

  return (
    <div>
      <Loading isLoading={user === undefined} />
      {
        user !== undefined && (
          <Switch>
            {
              Object.keys(ROUTE).map((key) => {
                const {
                  path,
                  param,
                  component,
                  privateRoute,
                  adminRoute
                } = ROUTE[key];
                if (privateRoute || adminRoute) {
                  return (
                    <PrivateRoute
                      path={`${path}${param ? `/:${param}` : ''}`}
                      key={path}
                      adminRoute={adminRoute}
                    >
                      {component}
                    </PrivateRoute>
                  );
                }
                return (
                  <Route
                    exact
                    key={path}
                    path={`${path}${param ? `/:${param}` : ''}`}
                    component={component}
                  />
                );
              })
            }
            <Route path="/">
              <Redirect to={ROUTE.SIGN_IN.path} />
            </Route>
          </Switch>
        )
      }
    </div>
  );
}

export default App;
