import React, { useContext } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { QuestContext } from 'src/providers/quest/QuestContext';
import { ROUTE } from 'src/routes';
import { useStyles } from './styles';
import Button from '../Button';

function ListQuests(): React.ReactElement {
  const styles = useStyles();
  const { quests } = useContext(QuestContext);
  const history = useHistory();

  return (
    <div className={styles.container}>
      {
        quests.map((quest) => (
          <Button
            key={quest.id}
            text={quest.name}
            className={styles.button}
            onClick={() => history.push(`${ROUTE.QUEST.path}/${quest.id}`)}
          />
        ))
      }
      {(quests.length === 1) && <Redirect to={`${ROUTE.QUEST.path}/${quests[0].id}`} />}
    </div>
  );
}

export default ListQuests;
