import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

const innerCircleSizeSmallerPx = 40; // amount in px the inner circle is smaller than the outer circle

export const useStyles = makeStyles((theme) => createStyles({
  container: {
    width: '100%',
    paddingBottom: '100%',
    position: 'relative',
    overflow: 'hidden',
    margin: 'auto'
  },
  circleOuter: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '100%'
  },
  circle: {
    position: 'absolute',
    width: `calc(100% - ${innerCircleSizeSmallerPx}px)`,
    height: `calc(100% - ${innerCircleSizeSmallerPx}px)`,
    top: `${innerCircleSizeSmallerPx / 2}px`,
    left: `${innerCircleSizeSmallerPx / 2}px`,
    borderRadius: '100%',
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/oldPaper.jpg)`,
    backgroundSize: 'cover',
    transition: 'box-shadow 2s ease-out'
  },
  circleOpen: {
    boxShadow: '0 0 20px #000'
  },
  letter: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    position: 'absolute',
    color: '#000',
    fontSize: `${innerCircleSizeSmallerPx / 3}px`,
    pointerEvents: 'none',
    userSelect: 'none',
    [theme.breakpoints.up('sm')]: {
      fontSize: `${innerCircleSizeSmallerPx / 2}px`
    }
  },
  biggerFont: {
    fontSize: `${innerCircleSizeSmallerPx / 1.5}px`,
    [theme.breakpoints.up('sm')]: {
      fontSize: `${innerCircleSizeSmallerPx}px`
    }
  },
  marker: {
    fontFamily: 'sans-serif',
    fontSize: '10px',
    lineHeight: '10px',
    overflow: 'hidden',
    textAlign: 'center'
  },
  mainMarker: {
    margin: 'auto',
    height: `${innerCircleSizeSmallerPx / 4}px`,
    width: `${innerCircleSizeSmallerPx / 4}px`,
    borderTop: `${innerCircleSizeSmallerPx / 3}px solid #000`,
    borderLeft: `${innerCircleSizeSmallerPx / 4}px solid transparent`,
    borderRight: `${innerCircleSizeSmallerPx / 4}px solid transparent`
  }
}));
