import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  container: {
  },
  id: {
    textAlign: 'center',
    fontFamily: 'sans-serif'
  },
  info: {
    margin: '10px'
  },
  delete: {
    backgroundColor: '#f00',
    backgroundImage: 'none',
    margin: '30px 0px 100px auto'
  }
}));
