import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  container: {
    transition: `${theme.transitionTime}ms`,
    transformOrigin: 'top',
    opacity: 0,
    overflow: 'hidden'
  },
  open: {
    opacity: 1
  }
}));
