import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  container: {
    maxWidth: theme.maxWidth,
    padding: theme.pagePadding,
    margin: 'auto'
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '3em',
    fontFamily: 'freebooter',
    textTransform: 'uppercase',
    margin: '60px 0px'
  },
  complete: {
    marginTop: '40px',
    textAlign: 'center',
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: theme.typography.body1.fontSize
  }
}));
