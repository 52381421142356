import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  safe: {
    margin: '20px auto',
    maxWidth: theme.maxWidth
  },
  pinContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px'
  },
  pinInput: {
    margin: '0px 10px',
    fontSize: '2em',
    width: '1em',
    textAlign: 'center',
    color: '#444'
  },
  isCorrect: {
    color: '#000',
    textShadow: '2px 1px 3px #222'
  }
}));
