import React, { ReactNode, useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { UserContext } from 'src/providers/user/UserProvider';

interface IProps {
  path: string;
  children: ReactNode;
  adminRoute?: boolean;
}

const PrivateRoute = ({ children, path, adminRoute }: IProps): React.ReactElement => {
  const { user, isAdmin } = useContext(UserContext);

  if (user === undefined) {
    return <></>;
  }
  if (!user || (adminRoute && !isAdmin)) {
    return <Redirect to="/" />;
  }

  return (
    <Route exact path={path}>
      {children}
    </Route>
  );
};

PrivateRoute.defaultProps = {
  adminRoute: false
};

export default PrivateRoute;
