// Easing functions
function easeInOutQuad(progress: number): number {
  let val = progress / 0.5;
  if (val < 1) return 0.5 * val * val;
  val -= 1;
  return -0.5 * (val * (val - 2) - 1);
}

function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function scrollDown(duration: number, distance?: number): Promise<void> {
  await delay(500);
  const startPosition = window.scrollY;
  const targetPosition = distance !== undefined
    ? startPosition + distance
    : document.documentElement.scrollHeight - window.innerHeight;
  const distanceToScroll = targetPosition - startPosition;
  const startTime = performance.now();

  function scrollAnimation(currentTime: number): void {
    const elapsedTime = currentTime - startTime;
    const scrollProgress = Math.min(elapsedTime / duration, 1);
    const easedProgress = easeInOutQuad(scrollProgress);
    const scrollValue = startPosition + distanceToScroll * easedProgress;

    window.scrollTo(0, scrollValue);

    if (elapsedTime < duration) {
      requestAnimationFrame(scrollAnimation);
    }
  }
  requestAnimationFrame(scrollAnimation);
}
