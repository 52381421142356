import firebase, { firestore } from 'src/firebase';
import { IUser } from 'src/interfaces/IUser';
import { createQuestsFB } from './quests';

async function getUserDocFB(uid: string): Promise<IUser|null> {
  if (!uid) return null;
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();
    const data = userDocument.data();
    if (!data) return null;
    return {
      uid,
      displayName: data.displayName,
      email: data.email,
      photoURL: data.photoURL
    };
  } catch (error) {
    console.error('Error fetching user', error);
    return null;
  }
}

async function createUserDocFB(fbUser: firebase.User): Promise<void> {
  if (!fbUser) return;
  try {
    const { email, displayName, photoURL } = fbUser;
    const userRef = firestore.doc(`users/${fbUser.uid}`);
    await userRef.set({
      displayName,
      email,
      photoURL
    });
    await createQuestsFB(fbUser.uid);
  } catch (error) {
    console.error('Error creating user document', error);
  }
}

export async function getOrSetUserFB(fbUser: firebase.User|null): Promise<IUser|null> {
  if (!fbUser) return null;
  const user = await getUserDocFB(fbUser.uid);
  if (user) return user;
  await createUserDocFB(fbUser);
  return getUserDocFB(fbUser.uid);
}
