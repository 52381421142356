
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { IAudio, IAudioBufferType, preloadAudioFiles } from 'src/interactions/audio';
import { useUserInteraction } from 'src/hooks/UserInteraction';
import Button from 'src/components/Button';
import { UtilsContext } from './UtilsContext';
import { useStyles } from './styles';
import { UserContext } from '../user/UserContext';

type IToast = {
  id: string;
  message: string;
  isError?: boolean;
};

const TOAST_TIMEOUT = 5000;

interface IProps {
  children?: ReactNode;
}

function UtilsProvider({ children }: IProps): React.ReactElement {
  const styles = useStyles();
  const [toasts, setToasts] = useState<IToast[]>([]);
  const [firstPage, setFirstPage] = useState('');
  const audioBuffers = useRef<IAudioBufferType[]>([]);
  const audioContext = useRef(new AudioContext());
  const { user, signOut } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    preloadAudioFiles()
      .then((preloaded) => { audioBuffers.current = preloaded; });
  }, []);

  const showToast = useCallback((message: string, isError?: boolean) => {
    if (toasts.some((t) => t.message === message)) return () => {};
    const id = Math.random().toString();
    const toast = {
      id,
      message,
      isError
    };
    setToasts((prev) => [...prev, toast]);
    const cancelToast = (): void => setToasts((prev) => prev.filter((t) => t.id !== id));
    setTimeout(cancelToast, TOAST_TIMEOUT);
    return cancelToast;
  }, [toasts]);

  const playSound = useCallback((soundName: IAudio): void => {
    const selectedBuffer = audioBuffers.current.find((buffer) => buffer.name === soundName)?.buffer;
    if (selectedBuffer) {
      const sourceNode = audioContext.current.createBufferSource();
      sourceNode.buffer = selectedBuffer;
      sourceNode.connect(audioContext.current.destination);
      sourceNode.start();
    }
  }, [audioBuffers]);

  useUserInteraction(() => { playSound('silence'); });

  const renderHeader = useCallback(() => {
    if (!user) return <></>;
    if (!firstPage && location.key) setFirstPage(location.key);
    return (
      <div className={styles.header}>
        <div>
        {
          (location.key !== firstPage) && (
            <Button
              onClick={history.goBack}
              imgUrl={`${process.env.PUBLIC_URL}/icons/back.svg`}
              admin
            />
          )
        }
        </div>
        <Button
          onClick={signOut}
          imgUrl={`${process.env.PUBLIC_URL}/icons/signOut.svg`}
          admin
        />
      </div>
    );
  }, [
    user,
    signOut,
    firstPage,
    history.goBack,
    location.key,
    styles.header
  ]);

  return (
    <UtilsContext.Provider
      value={
        {
          showToast,
          playSound
        }
      }
    >
      {renderHeader()}
      {children}
      <div className={styles.toasts}>
        {
          toasts.map((t) => (
            <div
              key={t.id}
              className={clsx(styles.toast, { [styles.error]: t.isError })}
            >
              {t.message}
            </div>
          ))
        }
      </div>
    </UtilsContext.Provider>
  );
}

UtilsProvider.defaultProps = {
  children: null
};

export { UtilsContext, UtilsProvider };
