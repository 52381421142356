import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  container: {
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  delImage: {
    position: 'absolute',
    top: '0',
    right: '0',
    border: 'none',
    outline: 'none',
    height: '30px',
    width: '30px',
    color: '#fff',
    borderRadius: '0% 0% 0% 50%',
    background: 'rgba(0, 0, 0, 0.5)',
    fontFamily: 'sans-serif'
  }
}));
