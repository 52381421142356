import { useEffect } from 'react';

// Can't auto play sounds on iOS so we need to wait for a user interaction to play silence
export function useUserInteraction(callback: () => void): void {
  useEffect(() => {
    const eventNames = ['touchstart', 'touchmove']; // Add additional event names as needed

    function handleUserInteraction():void {
      // Handle the user interaction here
      callback();

      // Clean up by removing the event listeners
      eventNames.forEach((eventName) => {
        document.removeEventListener(eventName, handleUserInteraction);
      });
    }

    // Add the event listeners on component mount
    eventNames.forEach((eventName) => {
      document.addEventListener(eventName, handleUserInteraction);
    });

    // Clean up by removing the event listeners on component unmount
    return () => {
      eventNames.forEach((eventName) => {
        document.removeEventListener(eventName, handleUserInteraction);
      });
    };
  }, [callback]);
}
