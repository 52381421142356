import React, {
  useCallback
} from 'react';
import { ILocationInput } from 'src/interfaces/IPuzzles';
import { checkAnswer, getLWL } from 'src/utils/string';
import { useStyles } from './styles';
import TextInput from '../TextInput';
import Map from '../Map';

function LocationInput({
  onComplete,
  isComplete,
  data
}: ILocationInput): React.ReactElement {
  const styles = useStyles();
  const [currentAnswer, setCurrentAnswer] = React.useState('');
  const xMark = {
    lat: data.lat,
    lng: data.lng
  };

  const onInputChange = useCallback((theirAnswer: string) => {
    setCurrentAnswer(theirAnswer);
    if (!isComplete && checkAnswer(theirAnswer, data.answer)) {
      onComplete();
    }
  }, [isComplete, data.answer, onComplete]);

  const renderLWLInstructions = useCallback(() => {
    if (!data.lwl) return null;
    const lineWordLetterExample = `The quick brown
fox jumps over
the lazy dog`;
    return (
      <>
        <div className={styles.clue}>[Line, Word, Letter] Cypher</div>
        <ul>
          <li className={styles.lwlInstructions}>First find the passage of text</li>
          <li className={styles.lwlInstructions}>Then line number</li>
          <li className={styles.lwlInstructions}>The word on that line</li>
          <li className={styles.lwlInstructions}>Count the right character in that word.</li>
        </ul>
        <TextInput
          value={lineWordLetterExample}
          className={styles.lwlExampleText}
          displayOnly
          noWrap
          textArea
        />
        <div className={styles.clue}>{`${getLWL(lineWordLetterExample, 'Hello')} = HELLO`}</div>
      </>
    );
  }, [
    data.lwl,
    // data.answer,
    styles.clue,
    styles.lwlExampleText,
    styles.lwlInstructions
  ]);

  return (
    <div className={styles.container}>
      {renderLWLInstructions()}
      <Map {...xMark} />
      <div className={styles.clue}>{data.clue}</div>
      {data.lwl?.lwlCode && <div className={styles.lwlCode}>{data.lwl.lwlCode}</div>}
      <TextInput
        onChange={onInputChange}
        displayOnly={isComplete}
        value={isComplete ? data.answer : currentAnswer}
      />
    </div>
  );
}

export default React.memo(LocationInput);
