export const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export function generateId(): string {
  const alphanumericChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const idLength = 8;
  const currentTime = new Date().getTime();
  let id = '';

  for (let i = 0; i < idLength; i += 1) {
    const randomIndex = Math.floor(Math.random() * alphanumericChars.length);
    id += alphanumericChars[randomIndex];
  }

  return `${id}-${currentTime}`;
}

export function caeserOffset(text: string, offset: number, givenAlphabet?: string): string {
  const alphabetArray = (givenAlphabet || alphabet).split('');
  const messageArray = text.toLocaleUpperCase().split('');
  const newMessageArray = messageArray.map((letter) => {
    const letterIndex = alphabetArray.indexOf(letter);
    if (letterIndex < 0) return letter;

    const newLetterIndex = (letterIndex + offset) % alphabetArray.length;
    return alphabetArray[newLetterIndex];
  });
  return newMessageArray.join('');
}

function removeSpaces(text: string): string {
  return text.toLocaleUpperCase().replace(/ /g, '');
}

export function checkAnswer(given: string, expected: string): boolean {
  return removeSpaces(given) === removeSpaces(expected);
}

export function removeNonValidChars(text: string): string {
  // remove all non-alphabet characters
  const regex = new RegExp(`[^${alphabet}\\s]`, 'g');
  const cleaned = text.toLocaleUpperCase().replace(regex, '');
  // remove excessive spaces
  return cleaned.replace(/[^\S\r\n]+/g, ' ');
}

type LWL = [number, number, number];

function createLWLMap(passage: string): Record<string, LWL[]> {
  const lwlMap: Record<string, LWL[]> = {};
  alphabet.split('').forEach((letter) => { lwlMap[letter] = []; });

  const text = removeNonValidChars(passage);

  text.split('\n').forEach((line, lineIndex) => {
    line.split(' ').forEach((word, wordIndex) => {
      word.split('').forEach((letter, letterIndex) => {
        if (lwlMap[letter] !== undefined) {
          lwlMap[letter].push([lineIndex + 1, wordIndex + 1, letterIndex + 1]);
        }
      });
    });
  });
  return lwlMap;
}

function randItem<T>(arr: T[]): T {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function NRandItems<T>(arr: T[], n: number): T[] {
  const items: T[] = [];
  const copy = [...arr];
  for (let i = 0; i < n; i += 1) {
    const item = randItem(copy);
    items.push(item);
    copy.splice(copy.indexOf(item), 1);
  }
  return items;
}

export function getLWL(sourceText: string, message: string): string {
  const lwlMap = createLWLMap(sourceText);
  const messageArray = message.toLocaleUpperCase().split('');
  return messageArray.map((letter) => {
    if (letter === ' ') return ' ';
    if (!lwlMap[letter].length) return letter;
    return `[${randItem(lwlMap[letter])}]`;
  }).join('');
}

export function stringify<T>(str: T): string {
  return JSON.stringify(str);
}

export function parse<T>(str: string): T {
  return JSON.parse(str);
}

export function getQuestName(): string {
  const quest = [
    'adventure',
    'journey',
    'chase',
    'pursuit',
    'quest',
    'odyssey',
    'hunt',
    'search'
  ];
  const adjectives = [
    'enchanted',
    'mysterious',
    'hidden',
    'ancient',
    'forgotten',
    'secret',
    'lost',
    'fabled',
    'elusive',
    'legendary',
    'lost',
    'cursed'
  ];
  const noun = [
    'soul',
    'treasure',
    'gold',
    'relic',
    'artifacts',
    'trove',
    'jewels',
    'booty',
    'riches',
    'bounty',
    'hoard',
    'antiques',
    'plunder'
  ];

  return `The ${randItem(quest)} for the ${randItem(adjectives)} ${randItem(noun)}`;
}

export function getQuestCompletionString(questName: string): string {
  const questTreasure = questName.split(' ').slice(-2).join(' ');
  return `Congratulations! You have completed ${questName}! Turns out that the real ${questTreasure} was the friends you made along the way!`;
}

export function getRandomSafeNumber(): string {
  const min = 100000; // Smallest 6-digit number (100000)
  const max = 999999; // Largest 6-digit number (999999)

  return `${Math.floor(Math.random() * (max - min + 1)) + min}`;
}
