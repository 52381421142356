import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  loading: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    backgroundColor: '#000',
    transition: `${theme.loading.removeAnimationTime}ms`,
    opacity: 0,
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/pirateHatBig.png)`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '50%'
  },
  visible: {
    opacity: 1
  }
}));
