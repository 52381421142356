import React, { useCallback, useEffect, useRef } from 'react';
import { IClueInput } from 'src/interfaces/IPuzzles';
import { checkAnswer } from 'src/utils/string';
import { IS_DEV } from 'src';
import { useStyles } from './styles';
import TextInput from '../TextInput';
import Hints from '../Hints';

function ClueInput({
  data,
  onComplete,
  isComplete
}: IClueInput): React.ReactElement {
  const styles = useStyles();
  const [currentAnswer, setCurrentAnswer] = React.useState('');
  const timeout = useRef<number>();

  useEffect(() => {
    if (!data.answer) {
      timeout.current = window.setTimeout(() => onComplete(), IS_DEV ? 500 : 30000);
    }
    return () => clearTimeout(timeout.current);
  }, [data.answer, onComplete]);

  const onInputChange = useCallback((theirAnswer: string) => {
    setCurrentAnswer(theirAnswer);
    if (!isComplete && checkAnswer(theirAnswer, data.answer)) {
      onComplete();
    }
  }, [data.answer, isComplete, onComplete]);

  return (
    <div className={styles.container}>
      <div className={styles.clue}>{data.clue}</div>
      {data.hints ? <Hints hints={data.hints} /> : null}
      {
        data.answer ? (
          <TextInput
            onChange={onInputChange}
            displayOnly={isComplete}
            value={isComplete ? data.answer : currentAnswer}
          />
        ) : null
      }
    </div>
  );
}

export default ClueInput;
