import React, {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import clsx from 'clsx';
import { UtilsContext } from 'src/providers/utils/UtilsContext';
import { IAudio } from 'src/interactions/audio';
import { ISafe } from 'src/interfaces/IPuzzles';
import { useStyles } from './styles';
import LetterWheel, { IOffsetChange } from '../LetterWheel';

const NUMBERS = '0123456789';

type IPinInput = {
  idxId: number;
  value: string;
  isCorrect: boolean;
};

function getPinInputs(secret: string, isComplete = false): IPinInput[] {
  return secret.split('').map((val, idx) => ({
    idxId: idx,
    value: isComplete ? val : '0',
    isCorrect: isComplete
  }));
}

function Safe({ data: secretPin, onComplete, isComplete }: ISafe): React.ReactElement {
  const styles = useStyles();
  const [currentAnswer, setCurrentAnswer] = useState(getPinInputs(secretPin));
  const [currentPinIdx, setCurrentPinIdx] = useState(0);
  const [currentlyAntiClockwise, setCurrentlyAntiClockwise] = useState(true);
  const { playSound } = useContext(UtilsContext);

  const offsetChange = useCallback(({ number, anticlockwise }: IOffsetChange) => {
    let newPinIdx = currentPinIdx;

    if (currentlyAntiClockwise !== anticlockwise) {
      // user changed direction so we increment the number
      // state is async so we need to keep value in newPinIdx here to avoid race conditions
      setCurrentPinIdx((prev) => {
        newPinIdx = (prev + 1) % currentAnswer.length;
        return newPinIdx;
      });
      setCurrentlyAntiClockwise(anticlockwise);
    }

    const newCurrentAnswer = currentAnswer.map((pinInput) => {
      if (pinInput.idxId === newPinIdx) {
        return {
          idxId: pinInput.idxId,
          value: NUMBERS[number],
          isCorrect: number === Number(secretPin[newPinIdx])
        };
      }
      return pinInput;
    });

    setCurrentAnswer(newCurrentAnswer);

    const currentNumberCorrect = number === Number(secretPin[newPinIdx]);
    let soundName: IAudio = currentNumberCorrect ? 'lock' : 'click';

    if (!isComplete && newCurrentAnswer.map(({ value }) => value).join('') === secretPin) {
      soundName = 'doorOpen';
      onComplete();
    }
    playSound(soundName);
  }, [
    isComplete,
    currentAnswer,
    currentPinIdx,
    currentlyAntiClockwise,
    secretPin,
    playSound,
    onComplete
  ]);

  useEffect(() => {
    if (isComplete) {
      setCurrentAnswer(getPinInputs(secretPin, true));
    }
  }, [isComplete, secretPin]);

  return (
    <div className={styles.safe}>
      <div className={styles.pinContainer}>
        {
          currentAnswer.map((pinInput) => (
            <div
              key={pinInput.idxId}
              className={clsx(styles.pinInput, { [styles.isCorrect]: pinInput.isCorrect })}
            >
              {pinInput.value}
            </div>
          ))
        }
      </div>

      <LetterWheel
        letters={NUMBERS}
        offsetChange={offsetChange}
        dialShadow={isComplete}
        isDisabled={isComplete}
        circleMarkers
      />
    </div>
  );
}

export default Safe;
