import React, {
  ReactElement,
  useEffect,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';

interface IProps {
  children: ReactElement|ReactElement[];
  isOpen: boolean;
}

function Accordian({ children, isOpen }: IProps): React.ReactElement {
  const styles = useStyles();
  const container = useRef<HTMLDivElement>(null);

  const getMaxHeight = (open: boolean): number => {
    if (open) {
      return container.current?.scrollHeight || 0;
    }
    return 0;
  };

  const [maxHeight, setMaxHeight] = useState(getMaxHeight(isOpen));

  useEffect(() => {
    setMaxHeight(getMaxHeight(isOpen));
  }, [isOpen]);

  return (
    <div
      className={
        clsx(
          styles.container,
          { [styles.open]: isOpen }
        )
      }
      style={{ maxHeight }}
      ref={container}
    >
      {children}
    </div>
  );
}

export default Accordian;
