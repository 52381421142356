import React from 'react';
import { IPuzzleStep, IQuest } from 'src/interfaces/IPuzzles';

type QuestContextType = {
  quests: IQuest[];
  updateStep: (step: IPuzzleStep) => Promise<void>;
  completeStep: (stepId: string) => Promise<void>;
  addNewQuest: () => Promise<void>;
};

export const defaultQuestContext: QuestContextType = {
  quests: [],
  updateStep: async (): Promise<void> => {},
  completeStep: async (): Promise<void> => {},
  addNewQuest: async (): Promise<void> => {}
};

export const QuestContext = React.createContext(defaultQuestContext);
