import React, { useCallback, useEffect, useState } from 'react';
import { ICaeserCipher } from 'src/interfaces/IPuzzles';
import { alphabet, caeserOffset, checkAnswer } from 'src/utils/string';
import { useStyles } from './styles';
import LetterWheel from '../LetterWheel';
import TextInput from '../TextInput';

function Caeser({
  data,
  onComplete,
  isComplete
}: ICaeserCipher): React.ReactElement {
  const styles = useStyles();
  const [message, setMessage] = useState('');
  const [offset, setOffset] = useState(0);
  const [currentAnswer, setCurrentAnswer] = React.useState('');

  useEffect(() => {
    if (!data.secretMessage) return;
    const offsetSecretMessage = caeserOffset(data.secretMessage, Math.ceil(Math.random() * alphabet.length - 1));
    setMessage(offsetSecretMessage || alphabet);
  }, [data.secretMessage]);

  const onInputChange = useCallback((theirAnswer: string) => {
    setCurrentAnswer(theirAnswer);
    if (!isComplete && checkAnswer(theirAnswer, data.answer)) {
      onComplete();
    }
  }, [data.answer, isComplete, onComplete]);

  return (
    <div className={styles.caeser}>
      {
      !data.secretMessage && (
        <TextInput
          textArea
          onChange={(val) => setMessage(val)}
          value={message}
        />
      )
      }
      <LetterWheel
        letters={alphabet}
        outerLetters={alphabet}
        offsetChange={({ number }) => setOffset(number)}
      />
      <TextInput
        textArea
        value={caeserOffset(message, offset)}
        displayOnly
      />
      <TextInput
        onChange={onInputChange}
        displayOnly={isComplete}
        value={isComplete ? data.answer : currentAnswer}
      />
    </div>
  );
}

Caeser.defaultProps = {
  secretMessage: ''
};

export default Caeser;
