import React from 'react';
import { IUser } from 'src/interfaces/IUser';

type UserContextType = {
  user: IUser|null|undefined;
  signInWithFacebook: () => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  signOut: () => Promise<void>;
  isSigningIn: boolean;
  isAdmin: boolean;
};

export const defaultUserContext: UserContextType = {
  user: undefined,
  signInWithFacebook: async (): Promise<void> => {},
  signInWithGoogle: async (): Promise<void> => {},
  signOut: async (): Promise<void> => {},
  isSigningIn: false,
  isAdmin: false
};

export const UserContext = React.createContext(defaultUserContext);
