import React from 'react';
import { useStyles } from './styles';

type IProps = {
  images: string[];
  onDelete?: (img: string) => void;
};

function Images({ images, onDelete }: IProps): React.ReactElement {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      {
        images.map((image) => (
          <div
            key={image}
            className={styles.imgContainer}
          >
            <img src={image} alt="uploaded" />
            {
              onDelete && (
                <button
                  type="button"
                  className={styles.delImage}
                  onClick={() => onDelete(image)}
                >
                  X
                </button>
              )
            }
          </div>
        ))
      }
    </div>
  );
}

Images.defaultProps = {
  onDelete: undefined
};

export default Images;
