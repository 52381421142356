import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    maxWidth: theme.maxWidth,
    padding: theme.pagePadding
  },
  addStat: {
    margin: '20px'
  },
  imgInput: {
    display: 'none'
  },
  uploadLocation: {
    marginTop: '60px',
    height: '50px'
  }
}));
