export type IAudio = 'silence'|'click'|'lock'|'doorOpen';
export type IAudioBufferType = {
  name: IAudio;
  buffer: AudioBuffer | null;
};

export const AUDIO: IAudio[] = ['silence', 'click', 'lock', 'doorOpen'];

export function preloadAudioFiles(): Promise<IAudioBufferType[]> {
  function preloadWavFile(url: string): Promise<AudioBuffer> {
    return new Promise((resolve, reject) => {
      const preloadingAudioContext = new AudioContext();

      fetch(url)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => preloadingAudioContext.decodeAudioData(arrayBuffer))
        .then((decodedData) => resolve(decodedData))
        .catch((error) => reject(error));
    });
  }

  const preloadPromises = AUDIO.map((name) => preloadWavFile(`${process.env.PUBLIC_URL}/audio/${name}.wav`)
    .then((buffer) => ({ name, buffer })));

  return Promise.all(preloadPromises)
    .then((preloadedBuffers) => preloadedBuffers.filter((buffer) => buffer.buffer !== null));
}
