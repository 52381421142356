import React from 'react';
import { IPosition } from 'src/interfaces/ILocation';

type UtilsContextType = {
  myLocation: IPosition|null;
  locationError: boolean;
  myMarker?: google.maps.Icon;
  xMarker?: google.maps.Icon;
};

export const defaultLocationContext: UtilsContextType = {
  myLocation: null,
  locationError: false
};

export const LocationContext = React.createContext(defaultLocationContext);
