import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  mapContainer: {
    width: '100%',
    paddingBottom: '100%',
    minHeight: '500px',
    margin: 'auto'
  }
}));
