import React, {
  useCallback,
  useContext,
  useRef,
  useState
} from 'react';
import Compressor from 'compressorjs';
import { IPosition } from 'src/interfaces/ILocation';
import { addNewLocationFB } from 'src/firebase/geoquery';
import { UtilsContext } from 'src/providers/utils/UtilsContext';
import { removeNonValidChars } from 'src/utils/string';
import { useStyles } from './styles';
import AdminMap from '../AdminMap';
import TextInput from '../TextInput';
import Button from '../Button';
import Images from '../Images';

const IMG_MAX_SIZE = 100 * 1024; // 100KB;
const IMG_MAX_DIMS = 500;

function UploadLocation(): React.ReactElement {
  const styles = useStyles();
  const { showToast } = useContext(UtilsContext);
  const [position, setPosition] = useState<IPosition>();
  const [lineWordLetter, setLineWordLetter] = useState('');
  const [clue, setClue] = useState('');
  const [images, setImages] = useState<string[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const uploadImageRef = useRef<HTMLInputElement>(null);

  const addImage = useCallback((imgFile: File|Blob, compression = 0.6) => {
    const initialSize = imgFile.size;
    /* eslint-disable no-new */
    new Compressor(imgFile, {
      quality: compression,
      maxWidth: IMG_MAX_DIMS,
      maxHeight: IMG_MAX_DIMS,
      success(result) {
        if (result.size < initialSize) {
          addImage(result); // We've compressed but try again to see if we can go smaller
        } else if (imgFile.size > IMG_MAX_SIZE && compression > 0) {
          const newLow = Math.max(compression - 0.2, 0);
          addImage(result, newLow);
        } else if (imgFile.size > IMG_MAX_SIZE) {
          showToast('Image is too big to upload', true);
        } else {
          const reader = new FileReader();
          reader.onload = (e) => {
            const img = e.target?.result;
            setImages((prev) => [...new Set([...prev, img as string])]);
          };
          reader.readAsDataURL(imgFile);
        }
      }
    });
  }, [showToast]);

  const onImageUpload = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const imgs = Array.from(event.target.files || []);
    imgs.forEach((imgFile) => {
      addImage(imgFile);
    });
  }, [addImage]);

  const uploadLocation = useCallback(async () => {
    if (!position || !clue || !images.length) return;
    setIsUploading(true);
    await addNewLocationFB({
      lineWordLetter,
      clue,
      images,
      ...position
    });
    showToast('Location uploaded');
    setClue('');
    setImages([]);
    setLineWordLetter('');
    setPosition(undefined);
    setIsUploading(false);
  }, [clue, images, lineWordLetter, position, showToast]);

  return (
    <div className={styles.container}>
      <AdminMap spot={position} setSpot={setPosition} />
      <TextInput
        textArea
        onChange={(val) => setLineWordLetter(removeNonValidChars(val))}
        value={lineWordLetter}
        placeholder="line-word-letter Text"
        noWrap
      />
      <TextInput
        textArea
        onChange={setClue}
        value={clue}
        placeholder="Clue"
      />
      <Images
        images={images}
        onDelete={(image) => setImages((prev) => prev.filter((img) => img !== image))}
      />
      <Button
        className={styles.addStat}
        onClick={() => uploadImageRef.current?.click()}
        text="Add Images"
      />
      <input
        ref={uploadImageRef}
        className={styles.imgInput}
        type="file"
        accept="image/*"
        onChange={onImageUpload}
        multiple
      />

      <Button
        onClick={uploadLocation}
        className={styles.uploadLocation}
        text="Upload Location"
        disabled={!clue || !images.length || !position || isUploading}
      />
    </div>
  );
}

export default UploadLocation;
