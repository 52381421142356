import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  container: {
    maxWidth: theme.maxWidth,
    padding: theme.pagePadding,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    marginTop: '10px'
  }
}));
